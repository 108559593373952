import styled from '@emotion/styled';
import rhythm from '../../utils/rhythm';
import breakpoints from '../../utils/responsive';

const Styles = styled.main({
  // Todo: Dry out Process / Work lists
  '.case-studies': {
    '>a': {
      display: 'block',
      marginBottom: `${rhythm(3)}`, // Equal to .mb-5

      '&:last-child': {
        marginBottom: 0,
      },

      h3: {
        textTransform: 'uppercase',
      },
    },

    [breakpoints.Mobile]: {
      '>a': {
        display: 'block',
        marginBottom: `${rhythm(2)}`, // Equal to .mb-5

        '&:last-child': {
          marginBottom: 0,
        },

        svg: {
          height: `${rhythm(5)}`,
          maxWidth: `${rhythm(5)}`,
        },
      },
    },

    [breakpoints.Desktop]: {
      '>a': {
        display: 'block',
        marginBottom: `${rhythm(2)}`, // Equal to .mb-5

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
});

export default Styles;
