import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Container, Row, Col } from 'react-bootstrap';

import * as Globals from '../../../globals';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo/seo';
import ChatBanner from '../../components/chatBanner/chatBanner';
import Styles from './index.styles';
import ContentCol from '../../components/contentCol/contentCol';

import { ReactComponent as ClientLogoWaxInsurance } from '../../images/clients/client-logo-wax.svg';
import { ReactComponent as ClientLogoSproutHealth } from '../../images/clients/client-logo-shg.svg';
import { ReactComponent as ClientLogoMuchSmarter } from '../../images/clients/client-logo-ms.svg';

const WorkCol = ({ children }) => {
  return (
    <Col xs={11} md={8}>
      {children}
    </Col>
  );
};

const WorkPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo title="Our Work" />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <h1>It started with vision, we made it reality</h1>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>

        <Container className="main">
          <section className="my-5">
            <Row className="mb-5 justify-content-center text-center">
              <ContentCol>
                <p className="lead mb-0">
                  Founders come to us with great ideas, unrelenting passion, and
                  an immediate need. We don't just find solutions that work in
                  the moment, we build products with a future.
                  <br />
                  <br />
                  Here are a few examples of businesses we've taken from idea to
                  launch and beyond.
                </p>
              </ContentCol>
            </Row>
          </section>

          <hr />

          <section className="my-5">
            <div className="mb-5 px-xl-4 text-center text-md-left case-studies">
              <Link to="/work/wax-insurance/">
                <Row className="align-items-center justify-content-center">
                  <Col className="mb-3 mb-md-0 text-center">
                    <ClientLogoWaxInsurance />
                  </Col>
                  <WorkCol>
                    <h3>Wax Insurance</h3>
                    <p>
                      Winning the first-to-market race with a product that
                      delivers on luxury expectations
                    </p>
                    <h5>Learn How We Build Better Faster</h5>
                  </WorkCol>
                </Row>
              </Link>

              <Link to="/work/much-smarter/">
                <Row className="align-items-center justify-content-center">
                  <Col className="mb-3 mb-md-0 text-center">
                    <ClientLogoMuchSmarter />
                  </Col>
                  <WorkCol>
                    <h3>Much Smarter</h3>
                    <p>
                      Helping an established analog company scale with
                      technology and enter new markets
                    </p>
                    <h5>Learn How We Transform Businesses</h5>
                  </WorkCol>
                </Row>
              </Link>

              <Link to="/work/sprout-health/">
                <Row className="align-items-center justify-content-center">
                  <Col className="mb-3 mb-md-0 text-center">
                    <ClientLogoSproutHealth />
                  </Col>
                  <WorkCol>
                    <h3>Sprout Health</h3>
                    <p>
                      Rebuilding an established property to outrank larger
                      brands in a fiercely competitive market
                    </p>
                    <h5>Learn How We Create Strategic Wins</h5>
                  </WorkCol>
                </Row>
              </Link>
            </div>
          </section>

          <hr />

          <Row className="testimonial-row mt-5 mb-6">
            <Col>
              <Img
                fluid={data.tom.childImageSharp.fluid}
                alt="Tom, Founder of Much Smarter"
                className="rounded-circle"
              />
            </Col>
            <Col>
              <blockquote>
                <p>
                  <span>&ldquo;</span>Generativ sees the whole board. They're
                  constantly thinking a few steps ahead, layering flexibility
                  &amp; durability into everything they do&mdash;strategy,
                  design, technology&mdash;so growth is faster, more agile,
                  and less expensive.
                </p>
                <footer>
                  Tom, Founder of{' '}
                  <a
                    href="https://www.muchsmarter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Much Smarter
                  </a>
                </footer>
              </blockquote>
            </Col>
          </Row>
        </Container>
      </Styles>
      <ChatBanner />
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-work.png" }) {
      ...imageFluidExtraLarge
    }
    tom: file(relativePath: { eq: "founders/founder-tom.jpg" }) {
        ...imageFluidSmallGrayscale
    }
  }
`;

export default WorkPage;
